<template>
  <div class="vx-row">
    <div class="vx-col w-full mb-base">
      <div class="vx-row mb-6">
        <div class="vx-col sm:w-1/6 w-full">
          <span>Code</span>
        </div>
        <div class="vx-col sm:w-5/6 w-full">
          <vs-input class="w-full" :disabled="true" v-model="combo.code" />
        </div>
      </div>
      <div class="vx-row mb-6">
        <div class="vx-col sm:w-1/6 w-full">
          <span>Name</span>
        </div>
        <div class="vx-col sm:w-5/6 w-full">
          <vs-input class="w-full" :disabled="true" v-model="combo.name" />
        </div>
      </div>
      <div class="vx-row mb-6">
        <div class="vx-col sm:w-1/6 w-full">
          <span>Valid From</span>
        </div>
        <div class="vx-col sm:w-5/6 w-full">
          <vs-input
            :max="combo.valid_to"
            type="date"
            class="w-full"
            v-model="combo.valid_from"
          />
        </div>
      </div>
      <div class="vx-row mb-6">
        <div class="vx-col sm:w-1/6 w-full">
          <span>Valid To</span>
        </div>
        <div class="vx-col sm:w-5/6 w-full">
          <vs-input
            :min="combo.valid_from"
            type="date"
            class="w-full"
            v-model="combo.valid_to"
          />
        </div>
      </div>

      <!-- territories -->
      <div class="vx-row mb-6">
        <div class="vx-col sm:w-1/6 w-full">
          <span>Territory</span>
        </div>
        <div class="vx-col sm:w-5/6 w-full">
          <multiselect
            :multiple="true"
            :taggable="true"
            track-by="name"
            class="selectExample"
            v-model="combo.territories"
            :options="territory"
            :allow-empty="false"
            :group-select="false"
            :max-height="120"
            placeholder=" Type to search"
            label="name"
          >
            <template slot="singleLabel" slot-scope="dt">
              <span class="option__desc">
                <span class="option__title"
                  >{{ dt.option.code }} {{ dt.option.name }}</span
                >
              </span>
            </template>
          </multiselect>
        </div>
      </div>

      <!-- Image -->
      <div class="vx-row mb-6">
        <div class="vx-col sm:w-1/6 w-full">
          <span>Upload Image</span>
        </div>
        <div class="vx-col sm:w-5/6 w-full">
          <input :disabled="true" type="file" @change="onFileChange" />
        </div>
      </div>

      <!-- image preview -->
      <div class="vx-row mb-6">
        <div class="vx-col sm:w-1/6 w-full">
          <span>Image Preview</span>
        </div>
        <div class="vx-col sm:w-5/6 w-full">
          <img
            :src="combo.image_url"
            alt="image preview"
            width="200"
            height="200"
          />
        </div>
      </div>
      <div class="vx-row mb-6">
        <vs-table stripe bordered :data="combo.items" style="width: 100%">
          <template slot="thead">
            <vs-th>Code</vs-th>
            <vs-th>Name</vs-th>
            <vs-th>Qty </vs-th>
            <vs-th>Handling Unit</vs-th>
            <vs-th>Type </vs-th>
          </template>
          <template slot-scope="{ data }">
            <vs-tr :key="index" v-for="(dt, index) in data">
              <vs-td>{{ dt.item_code != null ? dt.item_code : dt.code }}</vs-td>
              <vs-td>{{ dt.item_name != null ? dt.item_name : dt.name }}</vs-td>
              <vs-td>
                <vue-number-input
                  inline
                  controls
                  :min="1"
                  :step="1"
                  :disabled="true"
                  size="small"
                  v-model="combo.items[index].quantity"
                />
              </vs-td>
              <vs-td>{{ dt.unit_name != null ? dt.unit_name : "" }}</vs-td>
              <vs-td>{{
                dt.type == 1 ? "Freegood" : dt.type == "L" ? "Regular" : ""
              }}</vs-td>
            </vs-tr>
          </template>
        </vs-table>
      </div>

      <!-- submit button -->
      <div class="vx-row mb-6">
        <div class="vx-col sm:w-6/6 w-full">
          <vs-button
            :disabled="!selected.isEdit"
            class="mr-3 mb-2"
            @click="submitCombo()"
            >Submit</vs-button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueNumberInput from "@chenfengyuan/vue-number-input";
import moment from "moment";
import Datepicker from "vuejs-datepicker";

export default {
  components: {
    VueNumberInput,
    Datepicker,
  },
  props: {
    selected: Object,
    option: Object,
  },
  async mounted() {
    await this.fetchTerritory();
    // if (Object.keys(this.selected.itemKit).length > 0) {
    //   this.isEditorShow = true;
    //   await this.editOrShow();
    // }
  },
  destroyed() {
    this.selected.isEdit = true;
    this.selected.itemKit = {};
  },
  data: () => ({
    combo: {
      id: 0,
      code: "",
      name: "",
      company_id: 0,
      valid_from: "",
      valid_to: "",
      territories: [],
      items: [],
      image_name: null,
      image_path: null,
      image_url: null,
      daily_periodly: true,
      limit_per_customer: 1,
      limit_per_branch: 1,
    },
    params: {
      search: "",
      length: 10,
      page: 1,
      order: "asc",
      sort: "sku_code",
    },
    header: [
      {
        text: "Code",
        // value: "sku_code"
        sortable: false,
      },
      {
        text: "Name",
        // value: "item_name"
        sortable: false,
      },
      {
        text: "Unit",
        // value: "name"
        sortable: false,
      },
      // {
      //   text: "Action",
      //   sortable: false,
      // },
    ],
    responseData: {},
    counterDanger: false,
    indexDelete: "",
    itemsCosumable: false,
    isEditorShow: false,
    territory: [],
    unit: [],
  }),
  computed: {},
  watch: {
    territory: function (v) {
      if (Object.keys(this.selected.itemKit).length > 0) {
        this.isEditorShow = true;
        this.editOrShow();
      }
    },
  },
  methods: {
    onFileChange(e) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.postImage(files[0]);
    },
    postImage(file) {
      let formData = new FormData();
      formData.append("file", file);
      this.$vs.loading();
      this.$http
        .post("/api/v1/master/combo/upload-image", formData)
        .then((response) => {
          this.$vs.loading.close();
          this.combo.image_name = response.data.file_name;
          this.combo.image_path = response.data.path;
          this.combo.image_url = response.data.url;
        })
        .catch((error) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Error",
            text: error.response.data.message,
            color: "danger",
          });
        });
    },
    fetchTerritory() {
      this.$vs.loading();
      this.$http
        .get("/api/v1/master/multiple-territory")
        .then((response) => {
          this.territory = response.data.records;
          this.$vs.loading.close();
          console.log("fetchTerritory");
        })
        .catch((error) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Error",
            text: error.response.data.message,
            color: "danger",
          });
        });
    },
    // async setItemsTable(params) {
    //   this.params = params;
    //   var url = "/api/v1/item-available";
    //   const resp = await this.$http.get(url, {
    //     params: {
    //       search: params.search,
    //       limit: params.length,
    //       offset: params.length * (params.page - 1),
    //       order: params.sort,
    //       sort: params.order,
    //       territory_code: this.selected.territory.code,
    //       territory_id: this.selected.territory.ID,
    //       item_consumable: this.itemsCosumable,
    //     },
    //   });
    //   if (resp.code == 200) {
    //     this.responseData = {
    //       data: resp.data.ItemsAvailable,
    //       recordsTotal: resp.data.RecordsTotal,
    //       length: resp.data.ResultsTotal,
    //     };
    //   }
    // },
    async addItemsToLines(items) {
      if (!this.selected.isEdit) {
        return true;
      }

      // check if item already exist
      var checkResult = this.combo.items.findIndex((x) => {
        if (x.item_unit_id == items.item_unit_id) {
          return true;
        }
      });

      if (!checkResult) {
        await this.$vs.notify({
          title: "Error",
          text: "Item already exist",
          color: "danger",
        });
        return true;
      }

      this.$vs.loading();
      this.combo.items.push(items);
      var index = this.combo.items.indexOf(items);
      this.combo.items[index].quantity = 1;

      setTimeout(() => {
        this.$vs.loading.close();
      }, 1000);
    },
    async deleteItemLine(index, dt) {
      this.indexDelete = index;
      await this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: `Confirm`,
        text: "Are you sure remove " + dt.name + " from lines?",
        accept: this.confirmDelete,
      });
    },
    async confirmDelete() {
      await this.$vs.loading();

      setTimeout(() => {
        this.$vs.loading.close();
        if (this.combo.items[this.indexDelete].ID) {
          this.combo.delete_items.push(this.combo.items[this.indexDelete]);
        }
        this.combo.items.splice(this.indexDelete, 1);
      }, 1000);
    },
    async validateData() {
      // adjust territories values
      var territories = [];
      this.combo.territories.forEach((element) => {
        territories.push(element.id);
      });

      this.combo.territories = territories;

      // adjust items values
      var items = [];
      this.combo.items.forEach((element) => {
        items.push({
          quantity: element.quantity,
          item_unit_id: element.item_unit_id,
        });
      });
      this.combo.items = items;
    },
    async submitCombo() {
      // validate
      if (this.combo.items.length == 0) {
        let errorMessage = "Items can't be empty";
        await this.$vs.notify({
          title: "Danger",
          text: this.errorMessage,
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-check",
        });
        this.$emit("changeTab", 0, { name: "All" }, {}, false);
        return false;
      }

      if (this.combo.territories.length == 0) {
        let errorMessage = "territory can't be empty";
        await this.$vs.notify({
          title: "Danger",
          text: this.errorMessage,
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-check",
        });
        this.$emit("changeTab", 0, { name: "All" }, {}, false);
        return false;
      }

      if (this.combo.code == "") {
        let errorMessage = "code can't be empty";
        await this.$vs.notify({
          title: "Danger",
          text: this.errorMessage,
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-check",
        });
        this.$emit("changeTab", 0, { name: "All" }, {}, false);
        return false;
      }

      if (this.combo.name == "") {
        let errorMessage = "name can't empty";
        await this.$vs.notify({
          title: "Danger",
          text: this.errorMessage,
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-check",
        });
        this.$emit("changeTab", 0, { name: "All" }, {}, false);
        return false;
      }

      if (this.combo.valid_from == "") {
        let errorMessage = "valid from can't empty";
        await this.$vs.notify({
          title: "Danger",
          text: this.errorMessage,
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-check",
        });
        this.$emit("changeTab", 0, { name: "All" }, {}, false);
        return false;
      }

      if (this.combo.valid_to == "") {
        let errorMessage = "valid to can't empty";
        await this.$vs.notify({
          title: "Danger",
          text: this.errorMessage,
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-check",
        });
        this.$emit("changeTab", 0, { name: "All" }, {}, false);
        return false;
      }

      await this.validateData();

      // set company_id
      this.combo.company_id = this.$store.state.user.currentUser.company_id;
      this.combo.daily_periodly = this.combo.daily_periodly
        ? this.combo.daily_periodly == true
          ? 1
          : 2
        : 2;

      if (this.combo.limitPerCustomer == 0) {
        let errorMessage = "limit Per Customer can't 0";
        await this.$vs.notify({
          title: "Danger",
          text: this.errorMessage,
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-check",
        });
        this.$emit("changeTab", 0, { name: "All" }, {}, false);
        return false;
      }
      if (this.combo.limitPerBrach == 0) {
        let errorMessage = "limit Per Branch can't 0";
        await this.$vs.notify({
          title: "Danger",
          text: this.errorMessage,
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-check",
        });
        this.$emit("changeTab", 0, { name: "All" }, {}, false);
        return false;
      }

      if (this.isEditorShow) {
        this.$http
          .post("api/v1/master/combos", JSON.stringify(this.combo))
          .then((resp) => {
            if (resp.code == 200) {
              this.$vs.notify({
                title: "Success",
                text: "",
                color: "success",
                position: "top-right",
                iconPack: "feather",
                icon: "icon-check",
              });
            } else {
              this.$vs.notify({
                title: "Danger",
                text: resp.message,
                color: "danger",
                position: "top-right",
                iconPack: "feather",
                icon: "icon-check",
              });
            }
          });
      } else {
        this.$http
          .post("api/v1/master/combos", JSON.stringify(this.combo))
          .then((resp) => {
            if (resp.code == 200) {
              this.$vs.notify({
                title: "Success",
                text: "",
                color: "success",
                position: "top-right",
                iconPack: "feather",
                icon: "icon-check",
              });
            } else {
              this.$vs.notify({
                title: "Danger",
                text: resp.message,
                color: "danger",
                position: "top-right",
                iconPack: "feather",
                icon: "icon-check",
              });
            }
          });
      }

      this.$vs.loading.close();
      this.$emit("changeTab", 0, { name: "All" }, {}, false);
    },
    editOrShow() {
      this.$http
        .get("api/v1/master/combos/" + this.selected.itemKit.id)
        .then((resp) => {
          if (resp.code == 200) {
            this.combo.items = [];
            let combo = resp.data;
            this.combo.id = combo.id;
            this.combo.name = combo.name;
            this.combo.code = combo.code;
            this.combo.valid_from = moment(String(combo.valid_from))
              .utcOffset(7)
              .format("YYYY-MM-DD");
            this.combo.valid_to = moment(String(combo.valid_to))
              .utcOffset(7)
              .format("YYYY-MM-DD");
            this.combo.daily_periodly = combo.daily_periodly
              ? combo.daily_periodly === 1
              : false;

            this.combo.limit_per_customer = combo.limit_per_customer;
            this.combo.limit_per_branch = combo.limit_per_branch;
            // set relation
            // this.combo.territories = combo.territories;
            this.combo.items = combo.items;

            this.combo.territories = [];
            combo.territories.forEach((element) => {
              this.territory.forEach((item) => {
                if (element.territory_id == item.id) {
                  this.combo.territories.push(item);
                }
              });
              console.log(element.territory_id);
            });

            this.isEditorShow = true;
            // this.$vs.loading.close();
          } else {
            // this.$vs.loading.close();
            this.$vs.notify({
              title: "Danger",
              text: resp.message,
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          }
          this.$vs.loading.close();
        });
    },
  },
};
</script>
